<template>
  <div>
    <v-text-field :placeholder="$t('delivery.lang_couponCode')" class="rounded-lg" rounded outlined min-height="40px"
      v-model="coupon" :disabled="disabled" :readonly="disabled">
      <template v-slot:append>
        <v-btn v-if="showRemoveButton" depressed @click="cancelCoupon" slot="append" dense id="scan-btn-clear"
          class="rounded-lg scan-btn" color="error">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on"> mdi-trash-can-outline </v-icon>
            </template>
            <span>{{ $t("delivery.lang_remove") }}</span>
          </v-tooltip>
        </v-btn>
        <v-btn v-else depressed slot="append" class="rounded-lg scan-btn" id="scan-btn" color="accent"
          @click="checkCard(coupon)" :disabled="disabled">
          {{ $t("delivery.lang_apply") }}
        </v-btn>
      </template>
    </v-text-field>

    <h4 v-if="scanned_card && cartTotalSum < scanned_card.min_redeem_cart_value" class="error--text">
          {{ $t('generic.lang_please_reach_minimum_cart_value_first') + ': '}}
          {{ scanned_card.min_redeem_cart_value | currency }}
    </h4>
  </div>
</template>

<script>
import ENDPOINTS from "@/plugins/axios/endpoints";
import { createNamespacedHelpers } from "vuex";

export default {
  name: "CouponField",
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      balance: 0,
      scanned_card: null,
      error: null,
      coupon: null,
      checking: false
    };
  },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters([
      "cartTotalSum"
    ]),
    showRemoveButton() {
      return this.scanned_card && this.scanned_card?.balance > 0;
    },
    disabled() {
      return this.scanned_card !== null;
    }
  },
  watch: {
    value: {
      handler(val) {
        this.coupon = val?.uuid;
        this.scanned_card = val;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async checkCard(id) {
      try {
        this.checking = true;
        if (
          (id && id?.toUpperCase()?.includes("GF$")) ||
          id?.toUpperCase()?.includes("V$")
        ) {
          const voucherId = id?.replace(/(V\$|GF\$|v\$|gf\$)/g, "");
          const card = await this.$axios
            .get(ENDPOINTS.ERP.GIFTCARDS.GET(voucherId))
            .then(res => ({min_redeem_cart_value:10,...res.data.data}))
            .catch(err => {
              if (err?.status > 300) {
                throw new Error(this.$t("generic.lang_invalid_gift_card"));
              }
            });

          if (card) {
            //check if this card is empty
            if (parseFloat(card.balance) === 0) {
              const message =
                this.$t("generic.lang_empty_gift_card") +
                " " +
                this.$options.filters.currency(card.balance) +
                " , " +
                this.$t("generic.lang_use_another_card");
              throw new Error(message);
            }

            if (Number(card.min_redeem_cart_value) > this.cartTotalSum) {
              const message =
                this.$t('generic.lang_gift_card_amount') +
                ' ' +
                this.$options.filters.currency(card.balance) +
                ' , ' +
                this.$t('generic.lang_but_cant_be_used') +
                ' , ' +
                this.$t('generic.lang_please_reach_minimum_cart_value_first') + ' ' +
                this.$options.filters.currency(Number(card.min_redeem_cart_value))
                throw new Error(message);
            }

            //check if already used but still have balance
            if (
              parseFloat(card.balance) > 0 &&
              card.usedAt !== null &&
              parseInt(card.usedAt) > 0
            ) {
              const message =
                this.$t("generic.lang_gift_card_still_have_amount") +
                " " +
                this.$options.filters.currency(card.balance) +
                " , " +
                this.$t("generic.lang_do_you_really_want_use_the_gift_card");

              this.confirmCardUsage(card, message);
              return;
            }

            //check if never used and have balance
            if (parseFloat(card.balance) > 0 && card.usedAt === null) {
              const message =
                this.$t("generic.lang_gift_card_amount") +
                " " +
                this.$options.filters.currency(card.balance) +
                " , " +
                this.$t("generic.lang_do_you_really_want_use_the_gift_card");

              this.confirmCardUsage(card, message);
              return;
            }

          }
        } else {
          throw new Error(this.$t("generic.lang_invalid_gift_card"));
        }
      } catch (error) {
        this.$swal({
          title: this.$t("delivery.lang_error"),
          icon: "error",
          text: error
        });
      } finally {
        this.checking = false;
      }
    },
    confirmCardUsage(card, message) {
      this.$swal({
        text: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("delivery.lang_yes"),
        cancelButtonText: this.$t("delivery.lang_cancel"),
        showCloseButton: true,
        dangerMode: true
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.scanned_card = card;
          this.applyCoupon();
        }
      });
    },
    applyCoupon() {
      this.$emit("applyCoupon", this.scanned_card);
    },
    cancelCoupon() {
      this.$swal({
        text: this.$t("generic.lang_do_you_really_want_remove_this_coupon"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("delivery.lang_yes"),
        cancelButtonText: this.$t("delivery.lang_cancel"),
        showCloseButton: true,
        dangerMode: true
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.$emit("cancelCoupon", this.scanned_card);
          this.scanned_card = null;
          this.coupon = null;
        }
      });
    }
  }
};
</script>

<style>
#scan-btn,
#scan-btn-clear {
  position: absolute;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  min-height: calc(100% - 2.3px) !important;
}
</style>
