<template>
  <div
    :class="[
      this.checkBreakfastMode
        ? 'v-card border  rounded-lg pa-0 pb-0 mt-0 mb-2'
        : 'pt-4'
    ]"
  >
    <v-alert
      v-if="checkBreakfastMode"
      icon="mdi-calendar"
      type="info"
      color="primary"
      class="ma-0"
      dismissible
      @input="removeDay"
    >
      {{ this.day }}
    </v-alert>
    <!--start card empty message-->
    <v-sheet
      v-show="isCartEmpty"
      width="70%"
      color="transparent"
      class="mt-10 mb-14 grey--text text-center mx-auto justify-center"
    >
      <v-icon large>mdi-shopping</v-icon>

      <p class="caption">
        {{ $t("generic.lang_addItemMsg") }}
      </p>
    </v-sheet>
    <!--end card empty message-->

    <v-sheet
      v-show="!isCartEmpty"
      v-for="(order, index) in !checkBreakfastMode
        ? cart
        : cart.filter(item => item.date === day)"
      :key="index"
      color="secondary"
      class="rounded pa-0"
      :class="[checkBreakfastMode ? '' : 'my-3']"
    >
      <div class="d-flex py-4 px-0 ma-0 align-start">
        <!--start minus and plus buttons and amount-->
        <div class="text-center w-25">
          <div>
            <span class="font-weight-regular">{{ order.amount }}x</span>
          </div>

          <v-btn
            class="mr-1"
            color="accent"
            icon
            outlined
            rounded
            x-small
            :disabled="order.amount <= 1"
            @click="removeQTY(order)"
          >
            <v-icon class="pa-0 px-0" x-small>mdi-minus</v-icon>
          </v-btn>

          <v-btn
            color="accent"
            icon
            outlined
            rounded
            x-small
            @click="addQTY(order)"
          >
            <v-icon x-small>mdi-plus</v-icon>
          </v-btn>
        </div>
        <!--end minus and plus buttons and amount-->

        <div class="align-start mt-1">
          <!--start selected items-->
          <div class="d-flex">
            <div class="text-left justify-start">
              <span class="font-weight-bold" style="z-index: 1;">
                {{ order.name }}
              </span>
            </div>

            <div class="ml-1 text-left justify-start">
              <span class="font-weight-bold accent--text" style="z-index: 1;">
                {{ itemTotalPrice(order) | currency }}
              </span>

              <strong
                v-if="order.discount"
                class="font-weight-bold caption error--text"
                style="z-index: 1;"
              >
                {{ (-order.discount).toFixed(2) + "%" }}
              </strong>
            </div>
          </div>
          <!--end selected items-->

          <!--start selected extras -->
          <div
            v-if="Array.isArray(order.garnish) && order.garnish.length > 0"
            class="text-left justify-start small"
            style="margin-left: -12px;"
          >
            <ul>
              <li
                :key="ext.id + '-' + index"
                v-for="(ext, index) in order.garnish"
              >
                <strong class="primary--text">{{ ext.amount }}x </strong>
                {{ ext.name }}
                <strong class="primary--text">
                  {{ (ext.amount * ext.sell_price) | currency }}
                </strong>
              </li>
            </ul>
          </div>
          <!--end selected extras -->
        </div>

        <!--start cancelItem and openNote button-->
        <div class="d-flex align-start">
          <v-btn @click="deleteItem(order)" icon small color="black">
            <v-icon small>mdi-trash-can-outline</v-icon>
          </v-btn>

          <v-btn @click="openNote(order)" icon small color="black">
            <v-icon small>mdi-comment-outline</v-icon>
          </v-btn>
        </div>
        <!--end cancelItem and openNote button-->
      </div>

      <v-divider class="pa-0 ma-0" v-if="checkBreakfastMode" />
    </v-sheet>

    <!--start note dialog-->
    <item-note-dialog v-model="note_dialog" :dialog="note_dialog" />
    <!--end note dialog-->
  </div>
</template>

<script>
//import plugins
import { createNamespacedHelpers } from "vuex";

//import components
import ItemNoteDialog from "@/components/Card/ItemNoteDialog";
import { isBreakfast } from "../../helpers/breakfast";
import moment from "moment";

export default {
  name: "ShoppingCartOrdersCart",
  props: {
    day: {
      type: String,
      validator: val => moment(val, "DD.MM.YYYY").isValid()
    }
  },

  components: { ItemNoteDialog },

  data: () => ({
    note_dialog: false
  }),

  computed: {
    /*reference Order getters*/
    ...createNamespacedHelpers("Order").mapGetters([
      "cart",
      "isCartEmpty",
      "itemTotalPrice"
    ]),
    checkBreakfastMode() {
      return isBreakfast();
    }
  },

  methods: {
    ...createNamespacedHelpers("Order").mapMutations([
      "addQTY",
      "removeQTY",
      "deleteItem",
      "setSelectedItem",
      "replaceCart"
    ]),
    removeDay() {
      if (!this.isCartEmpty) {
        this.replaceCart([
          ...[...this.cart].filter(item => item.date !== this.day)
        ]);
      }
    },
    /*open note dialog*/
    openNote(item) {
      this.setSelectedItem(item);
      this.note_dialog = true;
    }
  }
};
</script>
