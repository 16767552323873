<template>
  <div></div>
</template>

<script>
import { breakfastDays } from "../../helpers/breakfast";
import { createNamespacedHelpers } from "vuex";

export default {
  name: "OrderSameBreakfastDialog",
  data() {
    return {
      resolve: null,
      days: breakfastDays(true),
      swalDialog: null
    };
  },
  computed: {
    ...createNamespacedHelpers("Order").mapGetters([
      "cart",
      "isCartEmpty",
      "is_AlreadyDuplicatedForNextDays"
    ])
  },
  methods: {
    ...createNamespacedHelpers("Order").mapMutations([
      "bookItem",
      "setOrderAsAlreadyDuplicated"
    ]),
    duplicateOrderForNextDays() {
      if (!this.isCartEmpty && this.days.length > 0) {
        const items = [...this.cart];
        this.days.forEach(day => {
          // duplicate items for each day
          items.forEach(item => {
            if (
              items.indexOf({
                ...item,
                date: day
              }) === -1
            )
              this.bookItem({
                ...item,
                date: day
              });
          });
        });
      }
    },
    openConfirmationDialog() {
      return new Promise(resolve => {
        this.resolve = resolve;
        this.swalDialog = this.$swal
          .fire({
            allowOutsideClick: () => !this.$swal.isLoading(),
            //title: "<strong>HTML <u>example</u></strong>",
            icon: "info",
            iconColor: this.$vuetify.theme.currentTheme.primary.base,
            html: this.$t("delivery.lang_order_same_breakfast"),
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonColor: this.$vuetify.theme.currentTheme.primary.base,
            confirmButtonText: this.$t("delivery.lang_yes"),
            confirmButtonAriaLabel: "Thumbs up, great!",
            cancelButtonText: this.$t("delivery.lang_no"),
            cancelButtonAriaLabel: "Thumbs down",
            showLoaderOnConfirm: true
          })
          .then(res => {
            if (res.isConfirmed) {
              this.duplicateOrderForNextDays();
              this.setOrderAsAlreadyDuplicated(true);
            }
            this.resolve(res.isConfirmed);
          });
      });
    }
  },
  beforeDestroy() {
    this.resolve = null;
    this.$swal.close();
  },
  async beforeMount() {
    if (!this.is_AlreadyDuplicatedForNextDays && this.days.length > 0)
      await this.openConfirmationDialog();
  }
};
</script>

<style scoped></style>
