<template>
  <!--Start checkout page Content-->
  <v-card
    tile
    elevation="0"
    color="white"
    class="mb-15 mt-5"
    :style="{
      minHeight: this.$vuetify.breakpoint.xs
        ? '600px'
        : this.$vuetify.breakpoint.sm
        ? '760px'
        : this.$vuetify.breakpoint.mdAndUp
        ? '800px'
        : '900px',
    }"
  >
    <v-sheet
      tile
      elevation="0"
      color="transparent"
      class="mx-auto justify-center"
      :width="$vuetify.breakpoint.mobile ? '95%' : '80%'"
    >
      <!--start checkout head-->
      <div>
        <div class="d-flex justify-end">
          <div style="max-width: 150px; max-height: 150px">
            <v-img
              v-if="images"
              :src="imageDim(images.logo, 'logo')"
              class="logo_style"
              width="100%"
              height="100%"
              contain
              max-width="150"
              max-height="150"
            />
          </div>
        </div>

        <div>
          <h4
            class="text-weight-bold"
            v-text="shop && shop.name"
          ></h4>

          <h5 class="text-weight-bold grey--text">
            {{ $t('delivery.lang_yourOrder') }}
          </h5>
        </div>
      </div>
      <!--end checkout head-->

      <v-card
        tile
        elevation="0"
        class="mx-auto mt-10"
        color="transparent"
      >
        <span class="mb-8 grey--text text-left subtitle-2">
          {{ currentTitle }}
        </span>

        <!--start steps-->
        <v-sheet
          tile
          elevation="0"
          color="transparent"
          class="d-flex justify-space-between align-center my-6"
        >
          <div
            v-for="(stepItems, i) in stepsItems"
            :key="i"
            class="d-flex align-center"
          >
            <v-avatar
              style="z-index: 5"
              size="20"
              :color="
                step > stepItems.step || step == 3
                  ? stepItems.completeColor
                  : stepItems.primaryColor
              "
            >
            </v-avatar>

            <v-progress-linear
              background-color="grey lighten-2"
              color="accent"
              absolute
              :value="progressVal"
            ></v-progress-linear>
          </div>
        </v-sheet>
        <!--end steps-->

        <v-window
          v-model="step"
          touchless
        >
          <!--start step 1-->
          <!--
          <v-window-item :value="1">
            start delivery and takeAway button
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <h6
                    class="text-center font-weight-meduim"
                    v-text="this.alternativeText"
                  ></h6>
                </v-col>

                <v-col cols="12">
                  <v-sheet
                    tile
                    elevation="0"
                    class="d-flex mx-auto justify-center"
                  >
                    <v-btn
                      :disabled="!offersDelivery || !maintenanceMode"
                      outlined
                      color="grey darken-1"
                      class="text-capitalize px-10 mr-2"
                      @click="delivery()"
                    >
                      Liefern
                    </v-btn>

                    <v-btn
                      :disabled="!offersPickUp || !maintenanceMode"
                      outlined
                      color="grey darken-1"
                      class="text-capitalize px-10 ml-2"
                      @click="takeAway()"
                    >
                      Abholen
                    </v-btn>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-card-text>
            end delivery and takeAway button
          </v-window-item>
          -->
          <!--end step 1-->

          <!--start step 2-->
          <v-window-item :value="1">
            <v-card-text>
              <!--start delivery personnal info-->
              <v-row v-if="!isLoggedIn">
                <v-col
                  cols="12"
                  class="mx-auto justify-center text-center mb-8 mt-2"
                >
                  <div class="d-flex mx-auto justify-center text-center">
                    <v-btn
                      text
                      :color="isGuest ? 'primary lighten-1' : ''"
                      class="d-flex align-center"
                      @click="showPsw(false)"
                    >
                      <span class="font-weight-bold text-capitalize my-auto">
                        {{ $t('delivery.lang_asAGuest') }}
                      </span>
                    </v-btn>

                    <v-btn
                      text
                      :color="isRegister ? 'primary lighten-1' : ''"
                      class="d-flex align-center"
                      @click="showPsw(true)"
                    >
                      <span class="font-weight-bold text-capitalize my-auto">
                        {{ $t('delivery.lang_Register') }}
                      </span>
                    </v-btn>

                    <v-btn
                      text
                      :color="isLoggedIn ? 'primary lighten-1' : ''"
                      class="d-flex align-center"
                      @click="showLogin()"
                    >
                      <span class="font-weight-bold text-capitalize my-auto">
                        {{ $t('delivery.lang_login') }}
                      </span>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-form
                    v-model="validCustomer"
                    ref="customerForm"
                  >
                    <v-row v-if="isDelivery">
                      <v-col cols="12">
                        <h6 class="text-center mb-6">
                          {{ $t('delivery.lang_whereShouldOrderGo') }}
                        </h6>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="4"
                        lg="4"
                        style="margin-top: -25px"
                      >
                        <span class="caption"
                          >{{ $t('delivery.lang_street') }}
                          <span class="error--text">*</span></span
                        >
                        <div>
                          <v-text-field
                            v-model="street"
                            name="streetNumber"
                            :rules="[rules.required]"
                            @keypress="checkPattern"
                            :placeholder="$t('delivery.lang_street')"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="4"
                        lg="4"
                        style="margin-top: -25px"
                      >
                        <span class="caption"
                          >{{ this.$t('delivery.lang_houseNumber') }}
                          <span class="error--text">*</span></span
                        >
                        <div>
                          <v-text-field
                            v-model="streetNumber"
                            name="streetNumber"
                            :placeholder="$t('delivery.lang_houseNumber')"
                            outlined
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="4"
                        lg="4"
                        style="margin-top: -25px"
                      >
                        <span class="caption"
                          >{{ disableZipCodeCheck ? $t('delivery.lang_deliveryArea') : $t('delivery.lang_postalCode') }}
                          <span class="error--text">*</span></span
                        >
                        <div>
                          <v-autocomplete
                            v-if="this.isDelivery"
                            ref="zipSelected"
                            :items="deliveryAreas"
                            item-text="name"
                            return-object
                            hide-selected
                            @change="setDeliverArea($event)"
                            @click:clear="setDeliveryInfo(null)"
                            outlined
                            dense
                            autocomplete="false"
                            auto-select-first
                            persistent-hint
                            :value="deliveryInfo"
                            :rules="[rules.required]"
                          >
                            <template
                              v-slot:
                              no-data
                            >
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ $t('delivery.lang_noResultsMatching') }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                          <v-text-field
                            v-else
                            v-model="zip"
                            type="number"
                            name="zip"
                            placeholder="24118"
                            outlined
                            autocomplete="false"
                            dense
                            :rules="[rules.required]"
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="4"
                        lg="4"
                        style="margin-top: -25px"
                      >
                        <span class="caption"
                          >{{ $t('delivery.lang_City') }}
                          <span class="error--text">*</span></span
                        >
                        <div>
                          <v-text-field
                            v-model="city"
                            name="city"
                            :placeholder="$t('delivery.lang_City')"
                            outlined
                            dense
                            :rules="[rules.required]"
                            @keypress="checkPattern"
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        style="margin-top: -25px"
                      >
                        <span class="caption">{{
                          $t('delivery.lang_floor')
                        }}</span>
                        <div>
                          <v-text-field
                            v-model="floor"
                            name="floor"
                            :placeholder="$t('delivery.lang_floor')"
                            outlined
                            dense
                            width="50%"
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <h6 class="text-center">
                          {{ $t('delivery.lang_howContactU') }}
                        </h6>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        style="margin-top: -25px"
                      >
                        <span class="caption"
                          >{{ $t('delivery.lang_Salutation')
                          }}<span class="error--text">*</span></span
                        >
                        <div>
                          <v-select
                            v-model="salutation"
                            :items="salutationItems"
                            :rules="[rules.required]"
                            name="salutation"
                            label=""
                            outlined
                            dense
                          ></v-select>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        style="margin-top: -25px"
                      ></v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        style="margin-top: -25px"
                      >
                        <span class="caption"
                          >{{ $t('delivery.lang_firstName')
                          }}<span class="error--text">*</span></span
                        >
                        <div>
                          <v-text-field
                            v-model="firstname"
                            :rules="[rules.required]"
                            @keypress="checkPattern"
                            name="firstname"
                            label=""
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        style="margin-top: -25px"
                      >
                        <span class="caption"
                          >{{ $t('delivery.lang_lastName')
                          }}<span class="error--text">*</span></span
                        >
                        <div>
                          <v-text-field
                            v-model="lastname"
                            :rules="[rules.required]"
                            @keypress="checkPattern"
                            name="lastname"
                            label=""
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        style="margin-top: -25px"
                      >
                        <span class="caption"
                          >E-Mail<span class="error--text">*</span></span
                        >
                        <div>
                          <v-text-field
                            v-model="email"
                            :rules="[rules.required, rules.email]"
                            name="email"
                            label=""
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        style="margin-top: -25px"
                      >
                        <span class="caption"
                          >{{ $t('delivery.lang_phoneNuber')
                          }}<span class="error--text">*</span></span
                        >
                        <div>
                          <v-text-field
                            v-model="mobile"
                            :rules="[rules.required]"
                            type="number"
                            name="mobile"
                            label=""
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        style="margin-top: -25px"
                      >
                        <span class="caption">{{
                          $t('delivery.lang_companyNumber')
                        }}</span>
                        <div>
                          <v-text-field
                            v-model="company"
                            @keypress="checkPattern"
                            name="company"
                            label=""
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        style="margin-top: -25px"
                      ></v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        style="margin-top: -25px"
                        v-if="showPassword"
                      >
                        <span class="caption"
                          >{{ $t('delivery.lang_password')
                          }}<span class="error--text">*</span></span
                        >

                        <v-text-field
                          ref="password"
                          v-model="password"
                          :append-icon="showHidePsw ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showHidePsw ? 'text' : 'password'"
                          name="password"
                          placeholder="*********"
                          outlined
                          :rules="[rules.required]"
                          dense
                          required
                          :hint="$t('delivery.lang_minChars8')"
                          @click:append="showHidePsw = !showHidePsw"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        style="margin-top: -25px"
                        v-if="showPassword"
                      >
                        <span class="caption"
                          >{{ $t('delivery.lang_password')
                          }}<span class="error--text">*</span></span
                        >

                        <v-text-field
                          ref="confirmPsw"
                          v-model="confirmPsw"
                          :append-icon="
                            showHideConfirmPsw ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          :type="showHideConfirmPsw ? 'text' : 'password'"
                          name="confirmPsw"
                          placeholder="*********"
                          outlined
                          dense
                          required
                          :rules="[
                            rules.required,
                            (v) => confirmPsw === password,
                          ]"
                          @click:append="
                            showHideConfirmPsw = !showHideConfirmPsw
                          "
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col
                  v-if="isDelivery"
                  cols="12"
                >
                  <v-alert
                    v-if="!checkIfCanDeliver"
                    color="warning"
                    dark
                  >
                    {{ $t('delivery.lang_selectAnArea') }}
                  </v-alert>
                </v-col>
                <v-col
                  v-if="isDelivery"
                  cols="12"
                >
                  <v-select
                    :items="userAddresses"
                    item-text="name"
                    item-value="id"
                    :rules="[checkIfCanDeliver]"
                    :placeholder="$t('delivery.lang_asap')"
                    v-model="selectedAddress"
                    :loading="loadingAddresses"
                    auto-select-first
                    outlined
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col v-if="isDelivery && selectedAddress === '0'">
                  <v-form
                    v-model="validAddress"
                    ref="addAddressForm"
                  >
                    <v-row>
                      <v-col cols="12">
                        <h6 class="text-center mb-6">
                          {{ $t('delivery.lang_whereShouldOrderGo') }}
                        </h6>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="4"
                        lg="4"
                        style="margin-top: -25px"
                      >
                        <span class="caption"
                          >{{ $t('delivery.lang_street')
                          }}<span class="error--text">*</span></span
                        >
                        <div>
                          <v-text-field
                            v-model="street"
                            name="street"
                            :placeholder="$t('delivery.lang_street')"
                            outlined
                            dense
                            :rules="[rules.required]"
                            @keypress="checkPattern"
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="4"
                        lg="4"
                        style="margin-top: -25px"
                      >
                        <span class="caption"
                          >{{ $t('delivery.lang_houseNumber')
                          }}<span class="error--text">*</span></span
                        >
                        <div>
                          <v-text-field
                            v-model="streetNumber"
                            name="streetNumber"
                            :placeholder="$t('delivery.lang_houseNumber')"
                            outlined
                            :rules="[rules.required]"
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="4"
                        lg="4"
                        style="margin-top: -25px"
                      >
                        <span class="caption" >>{{ disableZipCodeCheck ? $t('delivery.lang_deliveryArea') : $t('delivery.lang_postalCode') }}<span class="error--text">*</span></span
                        >
                        <div>
                          <v-autocomplete
                            v-if="isDelivery"
                            ref="zipSelected"
                            :items="deliveryAreas"
                            item-text="zip"
                            return-object
                            :rules="[rules.required, !!deliveryInfo]"
                            clearable
                            hide-selected
                            @change="setDeliverArea($event)"
                            @click:clear="setDeliveryInfo(null)"
                            outlined
                            dense
                            autocomplete="false"
                            persistent-hint
                            :value="deliveryInfo"
                          >
                            <template
                              v-slot:
                              no-data
                            >
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ $t('delivery.lang_noResults') }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                          <v-text-field
                            v-else
                            v-model="zip"
                            type="number"
                            name="zip"
                            :rules="[rules.required]"
                            placeholder="24118"
                            outlined
                            autocomplete="false"
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="4"
                        lg="4"
                        style="margin-top: -25px"
                      >
                        <span class="caption"
                          >{{ $t('delivery.lang_City')
                          }}<span class="error--text">*</span></span
                        >
                        <div>
                          <v-text-field
                            v-model="city"
                            name="city"
                            :rules="[rules.required]"
                            @keypress="checkPattern"
                            :placeholder="$t('delivery.lang_City')"
                            outlined
                            dense
                          ></v-text-field>
                        </div>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        style="margin-top: -25px"
                      >
                        <span class="caption">{{
                          $t('delivery.lang_floor')
                        }}</span>
                        <div>
                          <v-text-field
                            v-model="floor"
                            name="floor"
                            :placeholder="$t('delivery.lang_floor')"
                            outlined
                            dense
                            width="50%"
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
                <v-col
                  v-if="!isDelivery"
                  cols="12"
                >
                  <div class="d-flex justify-center">
                    <div style="max-width: 250px; max-height: 250px">
                      <v-img
                        v-if="images"
                        :src="imageDim(images.logo, 'logo')"
                        class="logo_style"
                        width="100%"
                        height="100%"
                        contain
                        max-width="250"
                        max-height="250"
                      />
                    </div>
                  </div>
                  <h4 class="my-4 text-center">
                    {{ $t('delivery.lang_pickupAddressIs') }}
                    {{
                      shop &&
                      shop.street +
                        ' ' +
                        shop.streetNumber +
                        ' ' +
                        shop.zip +
                        ' ' +
                        shop.city
                    }}
                  </h4>
                  <div></div>
                </v-col>
              </v-row>
              <!--end delivery personnal info-->
            </v-card-text>
          </v-window-item>
          <!--end step 2-->

          <!--start step 3-->
          <v-window-item
            v-if="!this.checkBreakfastMode"
            :value="2"
          >
            <v-card-text>
              <!--start note and time-->
              <v-sheet
                tile
                elevation="0"
                :width="$vuetify.breakpoint.mobile ? '100%' : '70%'"
              >
                <h6>
                  {{ $t('delivery.lang_whenWouldYouLikeToRecieveOrder') }}
                </h6>

                <div>
                  <span>{{ $t('delivery.lang_desiredDEliveryTime') }}</span>
                  <div>
                    <v-select
                      v-model="deliveryTime"
                      clearable
                      :items="availableTimes"
                      item-text="name"
                      item-value="value"
                      name="name"
                      :label="
                        isDelivery
                          ? $t('delivery.lang_deliveryTime')
                          : $t('delivery.lang_pickupTime')
                      "
                      :placeholder="
                        isDelivery
                          ? $t('delivery.lang_deliveryTime')
                          : $t('delivery.lang_pickupTime')
                      "
                      auto-select-first
                      outlined
                      dense
                    >
                    </v-select>
                    <v-date-picker
                        v-if="deliveryTime === 'schedule'"
                        v-model="scheduleDate"
                        color="primary"
                        no-title
                        full-width
                        :allowed-dates="isAllowedDate"
                        :first-day-of-week="1"
                        @update:picker-date="onCalendarRangeChange($event)"
                    >
                    </v-date-picker>
                    <v-select
                        v-if="deliveryTime === 'schedule'"
                        v-model="scheduleTime"
                        item-text="name"
                        item-value="value"
                        clearable
                        :items="availableScheduleTimes"
                        name="name"
                        :disabled="!scheduleDate"
                        :label="
                        isDelivery
                          ? $t('delivery.lang_deliveryTime')
                          : $t('delivery.lang_pickupTime')
                      "
                        :placeholder="
                        isDelivery
                          ? $t('delivery.lang_deliveryTime')
                          : $t('delivery.lang_pickupTime')
                      "
                        auto-select-first
                        outlined
                        dense
                    >
                    </v-select>
                  </div>
                </div>

                <div>
                  <span>{{ $t('delivery.lang_noteForRest') }}</span>
                  <div>
                    <v-textarea
                      v-model="freetext"
                      outlined
                      auto-grow
                      fill-height
                    >
                    </v-textarea>
                  </div>
                </div>

                <div>
                  <div style="border: 1px solid black">
                    <v-checkbox
                      v-model="saveNote"
                      value="value"
                      hide-details
                      style="margin-top: -4px"
                    >
                      <template v-slot:label>
                        <div class="mt-2">
                          {{ $t('delivery.lang_savethisNoteForNextOrder') }}
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                </div>
              </v-sheet>
              <!--end note and time-->
            </v-card-text>
          </v-window-item>
          <!--end step 3-->

          <!--start step 4-->
          <v-window-item :value="3">
            <!--start payement method-->
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                >
                  <h6 class="font-weight-bold">
                    {{ $t('delivery.lang_paymntMethod') }}
                  </h6>

                  <v-item-group
                    v-model="paymentType"
                    mandatory
                  >
                    <v-row>
                      <v-col
                        cols="6"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                        v-for="payment in payments"
                        :key="payment.id"
                      >
                        <v-item
                          v-if="payment.iconType === 'fa' && !(totalSumWithVoucher <=0 && payment.id !== 'localbee_pay_at_checkout_active')"
                          v-slot="{ active, toggle }"
                          :value="payment.id"
                        >
                          <div
                            style="border: 1px solid grey"
                            class="rounded-lg"
                          >
                            <v-card
                              :color="active ? 'grey lighten-1' : 'white'"
                              class="d-flex align-center rounded-lg"
                              style="border: 2px solid grey"
                              elevation="0"
                              dark
                              height="90"
                              @click="toggle"
                            >
                              <div
                                style="width: 100%; height: auto"
                                class="my-auto mx-auto justify-center align-center text-center"
                              >
                                <font-awesome-icon
                                  style="
                                    font-size: 30px;
                                    display: block;
                                    margin: 5px 0;
                                    opacity: 0.6;
                                  "
                                  class="mx-auto"
                                  :class="
                                    paymentType === payment.id
                                      ? 'text--white'
                                      : 'text-muted'
                                  "
                                  :icon="['fal', payment.icon]"
                                />
                                <span
                                  class="text-uppercase font-weight-bolder text-center"
                                  style="
                                    color: #515c6f !important;
                                    font-size: 120%;
                                  "
                                >
                                  {{ payment.name }}
                                </span>
                              </div>
                            </v-card>
                          </div>
                        </v-item>
                        <v-item
                          v-else-if="!(totalSumWithVoucher <=0 && payment.id !== 'localbee_pay_at_checkout_active')"
                          v-slot="{ active, toggle }"
                          :value="payment.id"
                        >
                          <div
                            style="border: 1px solid grey"
                            class="rounded-lg"
                          >
                            <v-card
                              :color="active ? 'grey lighten-1' : 'white'"
                              class="d-flex align-center rounded-lg overflow-hidden"
                              style="border: 2px solid grey"
                              elevation="0"
                              dark
                              height="90"
                              width="100%"
                              @click="toggle"
                            >
                              <v-img
                                v-if="payment.iconType === 'image'"
                                :src="payment.icon"
                                class="mx-auto py-6 payment-icon"
                              />
                            </v-card>
                          </div>
                        </v-item>
                      </v-col>
                      <!--


                                            <v-col cols="4">
                                              <v-item v-slot="{ active, toggle }" value="1">
                                                <div
                                                  style="border: 1px solid black"
                                                  class="rounded-xl"
                                                >
                                                  <v-card
                                                    :color="active ? 'grey lighten-1' : 'white'"
                                                    class="d-flex align-center rounded-xl"
                                                    style="border: 2px solid blue"
                                                    elevation="0"
                                                    outlined
                                                    dark
                                                    height="75"
                                                    @click="toggle"
                                                  >
                                                    <div
                                                      style="width: 100%; height: auto"
                                                      class="my-auto mx-auto justify-center align-center"
                                                    >
                                                      <v-img
                                                        src="@/assets/img/Sofortüberweisung_Logo.png"
                                                        class="my-auto mx-auto justify-center align-center"
                                                        style="width: 60%; height: auto"
                                                      />
                                                    </div>
                                                  </v-card>
                                                </div>
                                              </v-item>
                                            </v-col>

                                            <v-col cols="4">
                                              <v-item v-slot="{ active, toggle }">
                                                <div
                                                  style="border: 1px solid black"
                                                  class="rounded-xl"
                                                >
                                                  <v-card
                                                    :color="active ? 'grey lighten-1' : 'white'"
                                                    class="d-flex align-center rounded-xl"
                                                    style="border: 2px solid blue"
                                                    elevation="0"
                                                    outlined
                                                    dark
                                                    height="75"
                                                    @click="toggle"
                                                  >
                                                    <div
                                                      style="width: 100%; height: auto"
                                                      class="my-auto mx-auto justify-center align-center"
                                                    >
                                                      <v-img
                                                        src="@/assets/img/Klarna_Payment_Badge.webp"
                                                        class="my-auto mx-auto justify-center align-center"
                                                        style="width: 60%; height: auto"
                                                      />
                                                    </div>
                                                  </v-card>
                                                </div>
                                              </v-item>
                                            </v-col>

                                            <v-col cols="4">
                                              <v-item v-slot="{ active, toggle }" value="9">
                                                <div
                                                  style="border: 1px solid black"
                                                  class="rounded-xl"
                                                >
                                                  <v-card
                                                    :color="active ? 'grey lighten-1' : 'white'"
                                                    class="d-flex align-center rounded-xl"
                                                    style="border: 2px solid blue"
                                                    elevation="0"
                                                    outlined
                                                    dark
                                                    height="75"
                                                    @click="toggle"
                                                  >
                                                    <div
                                                      style="width: 100%; height: auto"
                                                      class="my-auto mx-auto justify-center align-center"
                                                    >
                                                      <v-img
                                                        src="@/assets/img/mastercard-logo.png"
                                                        class="my-auto mx-auto justify-center align-center"
                                                        style="width: 60%; height: auto"
                                                      />
                                                    </div>
                                                  </v-card>
                                                </div>
                                              </v-item>
                                            </v-col>

                                            <v-col cols="4">
                                              <v-item v-slot="{ active, toggle }">
                                                <div
                                                  style="border: 1px solid black"
                                                  class="rounded-xl"
                                                >
                                                  <v-card
                                                    :color="active ? 'grey lighten-1' : 'white'"
                                                    class="d-flex align-center rounded-xl"
                                                    elevation="0"
                                                    outlined
                                                    dark
                                                    height="75"
                                                    @click="toggle"
                                                  >
                                                    <div
                                                      style="width: 100%; height: auto"
                                                      class="my-auto mx-auto justify-center align-center"
                                                    >
                                                      <v-img
                                                        src="@/assets/img/Visa_2014_logo_detail.png"
                                                        class="my-auto mx-auto justify-center align-center"
                                                        style="width: 60%; height: auto"
                                                      />
                                                    </div>
                                                  </v-card>
                                                </div>
                                              </v-item>
                                            </v-col>-->
                    </v-row>
                  </v-item-group>
                </v-col>

                <v-col
                  v-if="!this.checkBreakfastMode"
                  cols="12"
                  xs="12"
                  sm="12"
                >
                  <h6 class="font-weight-bold">
                    {{ $t('delivery.lang_doUHaveVoucher') }}
                  </h6>
                  <div>
                    <span class="font-weight-medium">{{
                      $t('delivery.lang_Coupon')
                    }}</span>

                    <coupon-field
                      @applyCoupon="onApplyCoupon"
                      @cancelCoupon="onCancelCoupon"
                      v-model="this.voucher"
                    ></coupon-field>
                  </div>
                  <!--
                                    <h6 v-if="paymentType!==1" class="font-weight-bold">{{$t('delivery.lang_doUWantToTip')}}</h6>
                                    <div v-if="paymentType!==1">
                                      <span class="font-weight-medium">{{$t('delivery.lang_tip')}}</span>
                                      <v-text-field
                                          name="name"
                                          placeholder="0,00€"
                                          outlined
                                          dense
                                      ></v-text-field>
                                    </div>
                                  -->
                </v-col>
                <v-col cols="12">
                  <v-card
                    dark
                    color="indigo"
                    class="justify-start rounded-lg pa-4 text-center"
                    style="font-size: 26px; line-height: 30px"
                    @click="createOrder()"
                    :loading="loadingOrder"
                    :disabled="!canCheckout"
                  >
                    {{ $t('delivery.lang_orderApprove') }}
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <span
                    class="caption"
                    v-html="agreement"
                  >
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
            <!--end payement method-->
          </v-window-item>
          <!--end step 4-->
        </v-window>

        <!--start next and back button-->
        <v-card-actions>
          <v-btn
            color="accent"
            depressed
            v-if="step !== 1"
            :disabled="step === 1"
            @click="prev()"
          >
            {{ $t('generic.lang_previous') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            v-if="step === 1"
            color="accent"
            depressed
            :disabled="stepOne"
            @click="next()"
          >
            {{ $t('generic.lang_next') }}
          </v-btn>
          <v-btn
            v-if="step === 2"
            color="accent"
            depressed
            :disabled="stepTwo"
            @click="next()"
          >
            {{ $t('generic.lang_next') }}
          </v-btn>
        </v-card-actions>
        <!--end next and back button-->
        <v-card-actions class="mt-12 text-center">
          <v-spacer />
          <v-btn
            color="accent"
            class="mt-12 px-4"
            x-large
            @click="$router.push('/')"
          >
            {{ $t('generic.lang_backToMenu') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-sheet>
    <AuthDialogComponent
      v-if="dialog"
      v-model="dialog"
      :dialog="dialog"
      :step="'login'"
    />

    <!--@start order same breakfast for next days-->
    <order-same-breakfast-dialog />
    <!--@end order same breakfast for next days-->

    <!--Start Shopping Cart-->
    <shopping-cart
      v-if="this.$vuetify.breakpoint.smAndDown && this.checkBreakfastMode"
      v-model="shoppingCartDialog"
      :dialog="this.shoppingCartDialog"
    />
    <!--End Shopping Cart-->
  </v-card>
  <!--End checkout page Content-->
</template>

<script>
//import plugins
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment';
import ENDPOINTS from '@/plugins/axios/endpoints';
import ShoppingCart from '@/components/Card/ShoppingCart';

import AuthDialogComponent from '../Auth/AuthDialogComponent';
import validation from '../../mixins/validation';
import CouponField from './CouponField';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCreditCard,
  faCreditCardBlank,
  faEuroSign,
  faHandsHeart,
  faLineColumns,
  faPiggyBank,
  faReceipt,
} from '@fortawesome/pro-light-svg-icons';
import {
  customerEmail,
  customerFirstName,
  customerLastName,
  customerPhone,
  customerSalutation,
  isBreakfast,
} from '../../helpers/breakfast';
import OrderSameBreakfastDialog from './OrderSameBreakfastDialog';

library.add(
  faCreditCard,
  faCreditCardBlank,
  faHandsHeart,
  faEuroSign,
  faPiggyBank,
  faReceipt,
  faLineColumns
);
import { DEFAULT_PAYMENT_METHOD } from '@/utils/constants';
export default {
  name: 'CheckoutComponent',

  components: {
    OrderSameBreakfastDialog,
    AuthDialogComponent,
    ShoppingCart,
    CouponField,
  },
  mixins: [validation],
  //endpoint address localbee/v2/account/address get/post
  data() {
    return {
      shoppingCartDialog: false,
      dialog: false,
      loginStep: 1,
      isGuest: true,
      isRegister: false,
      isLogin: false,

      confirmPsw: '',
      showPassword: false,

      showHidePsw: false,
      showHideConfirmPsw: false,
      salutation: null,
      firstname: null,
      lastname: null,
      street: null,
      streetNumber: null,
      zip: null,
      city: null,
      floor: null,
      mobile: null,
      email: null,
      company: null,
      password: null,
      salutationItems: [
        {
          text: this.$t('delivery.lang_woman'),
          value: 'w',
        },
        {
          text: this.$t('delivery.lang_mr'),
          value: 'm',
        },
        {
          text: this.$t('delivery.lang_family'),
          value: 'f',
        },
        {
          text: this.$t('delivery.lang_miscellaneous'),
          value: 'd',
        },
      ],

      saveNote: false,
      step: 1,

      tabs: null,

      //ref="form"

      progressVal: 0,

      userAddresses: [],
      selectedAddress: null,
      loadingAddresses: false,
      loadingOrder: false,
      deliveryTime: null,
      scheduleDate: null,
      scheduleTime: null,
      selectedMonth: null,
      paymentIcons: {
        localbee_paypal_active: {
          icon: require('@/assets/logos/paypal.png'),
          iconType: 'image',
        },
        localbee_secupay_active: {
          icon: require('@/assets/logos/sp-logo.png'),
          iconType: 'image',
        },
        localbee_pay_at_checkout_active: {
          icon: 'money-check-edit',
          iconType: 'fa',
        },
        localbee_stripe_active: {
          icon: require('@/assets/logos/stripe-logo.svg'),
          iconType: 'image',
        },
      },
      updateInt: null,
      validAddress: false,
      validCustomer: false,
    };
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getUserAddress();
    } else {
      if (this.isDelivery) {
        this.zip = this.deliveryInfo.zip;
        this.city = this.disableZipCodeCheck? this.deliveryInfo.city || '' : '';
      }
    }

    this.$root.$on('logged', () => {
      this.getUserAddress();
    });

    this.updateInt = window.setInterval(() => {
      this.$forceUpdate();
    }, 1000 * 60);

    if (this.availableTimes && this.availableTimes.length > 0) {
      this.deliveryTime = this.availableTimes[0].value;
    }

    //init customer data if is breakfast mode
    if (this.checkBreakfastMode) {
      this.firstname = customerFirstName;
      this.lastname = customerLastName;
      this.email = customerEmail;
      this.mobile = customerPhone;
      this.salutation = customerSalutation;
    }
  },

  beforeDestroy() {
    this.$root.$off('logged');

    window.clearInterval(this.updateInt);

    this.$store.commit('Order/setVoucher', null);
  },

  methods: {
    ...createNamespacedHelpers('Config').mapMutations(['setDeliveryInfo']),
    ...createNamespacedHelpers('Order').mapMutations(['setVoucher']),
    onCalendarRangeChange(month) {
      this.selectedMonth = moment.utc(month, 'YYYY-MM');
    },
    mapPayments(payments) {
      const paymentMethods = [];

      for (const payment of payments) {
        let paymentName = payment.paymentName;
        if (
          payment.translations &&
          payment.translations[this.$i18n.locale]?.length > 0
        ) {
          paymentName = payment.translations[this.$i18n.locale];
        }
        // translate payment name
        paymentMethods.push({
          id: payment.id,
          name: paymentName,
          active: payment.active,
          icon: this.paymentIcons[payment.id]?.icon,
          iconType: this.paymentIcons[payment.id]?.iconType,
        });
      }

      return paymentMethods;
    },
    onApplyCoupon(coupon) {
      this.setVoucher(coupon);
    },
    onCancelCoupon() {
      this.setVoucher(null);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    /*if (!this.$refs.step5.validate()) {
        return;
    }*/
    showLogin() {
      /*this.isRegister = false;
      this.isGuest = false;
      this.showPassword = false;

      this.isLogin = false;*/

      this.dialog = true;
    },

    showPsw(val) {
      if (val == true) {
        this.isLogin = false;
        this.isGuest = false;

        this.isRegister = true;
        this.showPassword = true;

        this.customerCreateAccount = true;
      } else {
        this.isLogin = false;
        this.isRegister = false;

        this.isGuest = true;
        this.showPassword = false;

        this.customerCreateAccount = false;
      }
    },

    //on click on next button change stepper value
    next() {
      if (this.checkBreakfastMode) {
        if (this.step === 1) this.step = 3;
        this.progressVal = 100;
      } else {
        this.step++;
        this.progressVal = this.progressVal + 50;
      }
    },

    //on click on prev button change stepper value
    prev() {
      if (this.checkBreakfastMode) {
        if (this.step === 3) this.step = 1;
        this.progressVal = 0;
      } else {
        this.step--;
        this.progressVal = this.progressVal - 50;
      }
    },

    //on click on pay for the order button

    getUserAddress() {
      this.loadingAddresses = true;
      this.userAddresses = [];
      this.axios
        .get('localbee/v2/account/address')
        .then((res) => {
          if (res.status === 200) {
            this.userAddresses.push(
              ...res.data.data.map((address) => {
                if (address.default === 1) {
                  this.selectedAddress = address.uuid;
                }

                return {
                  id: address.uuid,
                  name:
                    address.floor +
                    ' ' +
                    address.street +
                    ' ' +
                    address.streetNumber +
                    ' ' +
                    address.zip +
                    ' ' +
                    address.city,
                  street: address.street,
                  streetNumber: address.streetNumber,
                  zip: address.zip,
                  city: address.city,
                  mobile: address.mobile,
                  default: address.default,
                };
              })
            );

            this.userAddresses.unshift({
              id: '0',
              name: this.$t('delivery.lang_addAddress'),
            });
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          if (err.status === 500) {
            this.$swal({
              title: this.$t('delivery.lang_error'),
              icon: 'error',
              text: this.$t(
                'delivery.lang_somethingWentWrongPleaseTryAgainLater'
              ),
            });
          }
        })
        .finally(() => {
          this.loadingAddresses = false;
        });
    },
    async addUserAddress() {
      return new Promise((resolve, reject) => {
        this.axios
          .post('localbee/v2/account/address', {
            street: this.street,
            streetNumber: this.streetNumber,
            floor: this.floor,
            city: this.city,
            zip: this.isDelivery ? this.deliveryInfo.zip : this.zip,
          })
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              resolve(res);
            }
          })
          .catch((err) => {
            if (err.status === 500) {
              this.$swal({
                title: this.$t('delivery.lang_error'),
                icon: 'error',
                text: this.$t(
                  'delivery.lang_somethingWentWrongPleaseTryAgainLater'
                ),
              });
              return;
            }
            if (err.data) {
              this.$swal({
                title: this.$t('delivery.lang_error'),
                icon: 'error',
                text: err.data.message,
              });
            } else {
              this.$swal({
                title: this.$t('delivery.lang_error'),
                icon: 'error',
                text: this.$t('delivery.lang_somethingWentWrong'),
              });
            }
            reject(err);
          });
      });
    },
    async createOrder() {
      if (!this.canCheckout) return;

      if (this.isDelivery && !this.deliveryInfo) {
        this.$root.$emit('showDeliveryAreasDialog');
        return;
      }

      if (this.isDelivery) {
        if (!this.offersDelivery) {
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: 'error',
            text: this.$t('delivery.lang_deliveryUnavailableMsg'),
          });
        }
      } else {
        if (!this.offersPickUp) {
          this.$swal({
            title: this.$t('delivery.lang_error'),
            icon: 'error',
            text: this.$t('delivery.lang_pickupUnavailableMsg'),
          });
        }
      }

      let timeToDeliver =
        this.isDelivery && this.deliveryInfo
          ? this.deliveryInfo.carDeliveryTime >
            this.deliveryInfo.motorcycleDeliveryTime
            ? this.deliveryInfo.carDeliveryTime
            : this.deliveryInfo.motorcycleDeliveryTime
          : 0;
      this.loadingOrder = true;
      this.$store.commit('Loader/changeStatus', true, { root: true }); //start global loading
      let preparationTime = this.isDelivery
        ? this.deliveryPreparationTime
        : this.pickUpPreparationTime;
      let form = {
        isDelivery: this.isDelivery,
        paymentType: this.paymentType,
        freetext: this.freetext,
        giftCard: {
          uuid: this.voucher?.uuid,
        },
        items: this.cart.map((item) => {
          let preparedItem = {
            id: item.id,
            name: item.name,
            sellPrice: item.sellPrice,
            mealSizeID: item.mealsize ? item.mealsize.id : 0,
            garnish: item.garnish,
            freetext: item.freetext,
            amount: item.amount,
            deliverPrice: item.deliverPrice,
            takeAwayPrice: item.takeAwayPrice,
            discount: item.discount,
            itemGroup: item.itemGroup,
          };

          if (
            this.checkBreakfastMode &&
            Object.prototype.hasOwnProperty.call(item, 'date')
          ) {
            preparedItem.date = item.date;
          }

          return preparedItem;
        }),
        isAsap: this.deliveryTime === 'asap',
        isScheduled: this.deliveryTime === 'schedule',
        isBreakfast: this.checkBreakfastMode,
      };

      if (this.checkBreakfastMode)
        form.extraParams = window.location.search?.replace('?', '&');

      if (this.isDelivery) {
        form.deliveryAreaID = this.deliveryInfo.id;
      }

      if(this.deliveryTime === 'asap'){
        form.deliveryTime = moment(this.now, 'DD:MM:YYYY HH:mm').add(preparationTime + timeToDeliver, 'minutes').format('HH:mm');
        form.deliveryDate = moment(this.now, 'DD:MM:YYYY HH:mm').format('YYYY-MM-DD');
      }else if(this.deliveryTime === 'schedule'){
        form.deliveryTime = this.scheduleTime;
        form.deliveryDate = this.scheduleDate;
      }else{
        form.deliveryTime = this.deliveryTime;
        form.deliveryDate = moment(this.now, 'DD:MM:YYYY HH:mm').format('YYYY-MM-DD');
      }

      if (!this.isLoggedIn) {
        if (this.isGuest) {
          form.isGuest = this.isGuest;
          form.customer = {
            salutation: this.salutation,
            firstname: this.firstname,
            lastname: this.lastname,
            street: this.street,
            streetNumber: this.streetNumber,
            zip: this.zip,
            city: this.city,
            floor: this.floor,
            mobile: this.mobile,
            phone: this.mobile,
            email: this.email,
            company: this.company,
          };
        } else if (this.isRegister) {
          form.customerCreateAccount = this.customerCreateAccount;
          form.customer = {
            salutation: this.salutation,
            firstname: this.firstname,
            lastname: this.lastname,
            street: this.street,
            streetNumber: this.streetNumber,
            zip: this.zip,
            city: this.city,
            floor: this.floor,
            mobile: this.mobile,
            phone: this.mobile,
            email: this.email,
            company: this.company,
            password: this.password,
          };
        }
      } else {
        form.addressId = this.selectedAddress;
        if (this.selectedAddress !== '0') {
          let selectedAddress = this.userAddresses.find(
            (add) => add.id === this.selectedAddress
          );

          form.customer = {
            street: selectedAddress.street,
            streetNumber: selectedAddress.streetNumber,
            zip: selectedAddress.zip,
            city: selectedAddress.city,
            floor: selectedAddress.floor,
            mobile: selectedAddress.mobile,
          };
        } else {
          form.customer = {
            street: this.street,
            streetNumber: this.streetNumber,
            zip: this.zip,
            city: this.city,
            floor: this.floor,
            mobile: this.mobile,
          };
        }
      }
      if (this.selectedAddress === '0') {
        await this.addUserAddress().then((res) => {
          form.addressId = res.data.uuid;
          this.axios
            .post(ENDPOINTS.DELIVERY.ORDERS.CREATE, form)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                if (
                  this.paymentType === 'localbee_paypal_active' ||
                  this.paymentType === 'localbee_stripe_active'
                ) {
                  window.location = res.data.url;
                } else {
                  this.$store.commit('Order/setVoucher', null);
                  this.$router
                    .push({
                      name: 'TrackingOrder',
                      query: {
                        orderNumber: this.checkBreakfastMode
                          ? res.data.data.ordersUUID?.join('|')
                          : res.data.data.orderUUID,
                      },
                    })
                    .then(() => {
                      this.$store.dispatch('Order/emptyCart');
                    });
                }
              }
            })
            .catch((err) => {
              if (err.data) {
                this.$swal({
                  title: this.$t('delivery.lang_error'),
                  icon: 'error',
                  text: err.data.error.message,
                });
              } else {
                this.$swal({
                  title: this.$t('delivery.lang_error'),
                  icon: 'error',
                  text: this.$t('delivery.lang_somethingWentWrong'),
                });
              }
            })
            .finally(() => {
              this.$store.commit('Loader/changeStatus', false, { root: true }); //start global loading
              this.loadingOrder = false;
            });
        });
      } else {
        this.axios
          .post(ENDPOINTS.DELIVERY.ORDERS.CREATE, form)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              if (
                this.paymentType === 'localbee_paypal_active' ||
                this.paymentType === 'localbee_stripe_active'
              ) {
                window.location = response.data.url;
              } else {
                this.$store.commit('Order/setVoucher', null);
                this.$router
                  .push({
                    name: 'TrackingOrder',
                    query: {
                      orderNumber: this.checkBreakfastMode
                        ? response.data.data.ordersUUID?.join('|')
                        : response.data.data.orderUUID,
                    },
                  })
                  .then(() => {
                    this.$store.dispatch('Order/emptyCart');
                  });
              }
            }
          })
          .catch((err) => {
            if (err.data) {
              this.$swal({
                title: this.$t('delivery.lang_error'),
                icon: 'error',
                text: err.data.error.message,
              });
            } else {
              this.$swal({
                title: this.$t('delivery.lang_error'),
                icon: 'error',
                text: this.$t('delivery.lang_somethingWentWrong'),
              });
            }
          })
          .finally(() => {
            this.$store.commit('Loader/changeStatus', false, { root: true }); //start global loading
            this.loadingOrder = false;
          });
      }
    },
    closeLoginDialog() {
      this.dialog = false;
    },
    setDeliverArea(deliveryArea) {
      if (typeof deliveryArea === 'object' && deliveryArea) {
        this.zip = deliveryArea.zip;
        this.city = this.disableZipCodeCheck? deliveryArea.city || '' : '';
        this.setDeliveryInfo(deliveryArea);
      } else if (deliveryArea) {
        this.zip = deliveryArea;
        let searchArea = this.deliveryAreas.find(
          (area) => deliveryArea === area.zip
        );
        if (searchArea) {
          this.setDeliveryInfo(searchArea);
          this.city = this.disableZipCodeCheck? searchArea.city || '' : '';
        }
      } else {
        this.zip = null;
        this.setDeliveryInfo(null);
      }
    },

    imageDim(logo, type) {
      if (!logo) return '';
      let baseUrl = 'https://img.3pos.de/unsafe/';
      let url = logo.substring(baseUrl.length);
      let filePath = url.substring(url.split('/')[0].length);

      if (type === 'logo') {
        return baseUrl + '0x350/filters:quality(65)' + filePath;
      } else {
        return baseUrl + '0x1920/filters:quality(65)' + filePath;
      }
    },
  },

  computed: {
    ...createNamespacedHelpers('Order').mapGetters(['voucher','totalSumWithVoucher', "cartTotalSum"]),
    checkBreakfastMode() {
      return isBreakfast();
    },
    stepsItems() {
      if (this.checkBreakfastMode) {
        return [
          {
            step: 0,
            primaryColor: 'grey lighten-2',
            completeColor: 'accent',
          },
          {
            step: 1,
            primaryColor: 'grey lighten-2',
            completeColor: 'accent',
          },
        ];
      } else
        return [
          {
            step: 0,
            primaryColor: 'grey lighten-2',
            completeColor: 'accent',
          },
          {
            step: 1,
            primaryColor: 'grey lighten-2',
            completeColor: 'accent',
          },
          {
            step: 2,
            primaryColor: 'grey lighten-2',
            completeColor: 'accent',
          },
        ];
    },
    agreement() {
      return this.$t(
        'delivery.lang_byClickingOnPaymentObligationYouConfirmTheshoppin'
      )
        .toLowerCase()
        .replace(
          this.$t('delivery.lang_conditions').toLowerCase(),
          "<a href='/agb' class='accent--text'>" +
            this.$t('delivery.lang_conditions') +
            '</a>'
        )
        .toLowerCase()
        .replace(
          this.$t('delivery.lang_privacyPolicy').toLowerCase(),
          "<a href='/datenschutz' class='accent--text'>" +
            this.$t('delivery.lang_privacyPolicy') +
            '</a>'
        );
    },
    stepOne() {
      if (this.isLoggedIn) {
        return !(this.selectedAddress === '0'
          ? this.validAddress
          : this.checkIfCanDeliver);
      } else if (this.isGuest || this.isRegister) {
        return !this.validCustomer;
      }
      return false;
    },
    stepTwo() {
      if(this.deliveryTime === 'schedule'){
        return this.scheduleDate === null || this.scheduleTime === null;
      }
      return this.deliveryTime === null;
    },
    checkIfCanDeliver() {
      if (this.isDelivery && this.isLoggedIn && this.selectedAddress !== '0') {
        let defaultAddress = this.userAddresses.find(
          (address) => this.selectedAddress === address.id
        );
        let area = null;
        if (defaultAddress) {
          area = this.deliveryAreas.find(
            (searchArea) => searchArea.zip === defaultAddress.zip
          );
          if (!area) {
            return false;
          } else {
            this.setDeliveryInfo(area);
          }
        }
        if (!this.deliveryInfo) {
          return false;
        }
      }

      return true;
    },
    canCheckout() {
      if (this.voucher && this.voucher.min_redeem_cart_value > this.cartTotalSum) {
        return false;
      }

      return this.cart.length > 0;
    },
    payments() {
      let payments = this.mapPayments(
        this.isDelivery ? this.deliveryPayments : this.takeawayPayments
      );
      if (this.checkBreakfastMode) {
        //show only paypal and concardis for breakfasts
        if (payments && payments.length > 0) {
          return payments.filter((p) =>
            ['localbee_takeaway_paypal_active'].includes(p.id)
          );
        }

        return [];
      } else {
        if (payments && payments.length > 0) {
          return payments.filter((p) => p.active);
        }

        return [DEFAULT_PAYMENT_METHOD];
      }
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return this.$t('delivery.lang_onlyAFewMoreInfoLeft');
        case 2:
          return this.$t('delivery.lang_onlyAFewMoreInfoLeft');
        case 3:
          return this.$t('delivery.lang_almostDone');
        default:
          return this.$t('delivery.lang_accountcreated');
      }
    },

    ...createNamespacedHelpers('Order').mapGetters(['cart']),
    ...createNamespacedHelpers('auth').mapGetters(['isLoggedIn']),
    ...createNamespacedHelpers('Config').mapGetters([
      'images',
      'shop',
      'disableZipCodeCheck',
      'times',
      'openingTimes',
      'deliveryPreparationTime',
      'pickUpPreparationTime',
      'maintenanceMode',
      'offersDelivery',
      'offersPickUp',
      'alternativeText',
      'isDelivery',
      'deliveryInfo',
      'deliveryAreas',
      'deliveryPayments',
      'takeawayPayments',
      'now',
      'getScheduleDayTimes',
      'enableOrderScheduling'
    ]),
    availableTimes(){
      let tmp = [
        ...this.times
      ]
      if(this.enableOrderScheduling){
        tmp.splice(1, 0,
            {
              name: this.$t('delivery.lang_scheduleOrder'),
              value: 'schedule'
            }
        )
      }
      return tmp;
    },
    availableScheduleTimes(){
      if(this.scheduleDate){
        return this.getScheduleDayTimes(moment(this.scheduleDate,'YYYY-MM-DD').weekday())
      }
      return [];
    },
    availableDates(){
      //this.currentMonth
      //moment(this.now, 'DD:MM:YYYY HH:mm');
      let currentTimeMoment = moment(this.now, 'DD:MM:YYYY HH:mm');
      let filteredDates = [];
      if(this.selectedMonth?.month() === currentTimeMoment.month()){
        for(let paginatingMoment = moment(currentTimeMoment); paginatingMoment.isSameOrBefore(currentTimeMoment.endOf('month'));paginatingMoment.add(1, 'day')){
          if(this.openingTimes[paginatingMoment.weekday()].open)
            filteredDates.push(paginatingMoment.format('YYYY-MM-DD'))
        }
      }else if(this.selectedMonth?.month() > currentTimeMoment.month() || this.selectedMonth.isSameOrAfter(currentTimeMoment)){
        for(let paginatingMoment = moment(this.selectedMonth.startOf('month')); paginatingMoment.isSameOrBefore(this.selectedMonth.endOf('month'));paginatingMoment.add(1, 'day')){
          if(this.openingTimes[paginatingMoment.weekday()].open)
            filteredDates.push(paginatingMoment.format('YYYY-MM-DD'))
        }
      }

      return filteredDates;
    },
    isAllowedDate() {
      return dateToCheck => {
        const foundedDate = this.availableDates?.find(day => day === dateToCheck);

        return foundedDate;
      };
    },
    paymentType: {
      get() {
        return this.$store.state.Checkout.paymentType;
      },
      set(val) {
        this.$store.commit('Checkout/setPaymentType', val);
      },
    },
    freetext: {
      get() {
        return this.$store.state.Checkout.freetext;
      },
      set(val) {
        this.$store.commit('Checkout/setFreeText', val);
      },
    },
  },
};
</script>
<style scoped>
.logo_style {
  background-color: #fff;
  border-radius: 25px;
}

.payment-icon {
  max-height: 50px !important;
  min-height: 50px !important;
  max-width: 70px;
}
</style>
