<template>
  <div>
    <!--start checkout-component-->
    <checkout-component />
    <!--end checkout-component-->
  </div>
</template>

<script>
//import components
import CheckoutComponent from "@/components/Checkout/CheckoutComponent.vue";
import order from "@/middlewares/order";

export default {
  name: "Checkout",
  mixins:[order],
  components: { CheckoutComponent },
};
</script>
