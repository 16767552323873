<template>
  <div>
    <!--Start shopping cart-->
    <div class="text-center">
      <v-dialog
        v-model="visible"
        width="100%"
        fullscreen
        scrollable
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <!--Start Menu Page Basket button-->
          <v-btn
            v-bind="attrs"
            v-on="on"
            dark
            width="100%"
            height="50px"
            class="text-capitalize"
            color="accent"
            style="border-top-right-radius: 60px; border-top-left-radius: 60px; position: fixed; bottom: 0%; right: 0%; left: 0%; z-index: 4"
          >
            <!--todo:HI please remove style attr and use class or id to apply this style -->
            <div class="d-flex justify-center">
              <div>
                <v-badge
                  :content="NProductSelect"
                  :value="NProductSelect"
                  color="primary"
                  overlap
                  bottom
                >
                  <v-icon class="mt-0" size="32px" color="white" dark>
                    mdi-purse
                  </v-icon>
                </v-badge>
              </div>

              <div>
                <h6
                  class="font-weight-regular font-size-sm white--text pl-4 pt-2"
                >
                  {{ $t("delivery.lang_cart") + " " }}
                  <TotalWrapper :total="totalSumWithVoucher"/>
                </h6>
              </div>
            </div>
          </v-btn>
          <!--End Menu Page Basket button-->
        </template>

        <!--Start Shopping Cart Content-->
        <v-card color="bg">
          <v-toolbar
            elevation="0"
            class="py-5 px-2 transparent border-bottom"
            max-height="56"
          >
            <v-toolbar-items class="d-flex">
              <div>
                <v-toolbar-title
                  class="font-weight-medium font-size-sm pr-2 primary--text text-h6"
                >
                  {{ $t("delivery.lang_cart") }}
                </v-toolbar-title>
              </div>

              <div>
                <v-badge
                  :content="NProductSelect"
                  :value="NProductSelect"
                  color="accent"
                  overlap
                  bottom
                  bordered
                >
                  <v-icon color="primary" size="27px">
                    mdi-purse-outline
                  </v-icon>
                </v-badge>
              </div>
            </v-toolbar-items>
            <v-spacer></v-spacer>

            <v-btn
              class="mb-6"
              x-large
              color="primary"
              icon
              dark
              @click="visible = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="mx-0 pt-5 ">
            <div class="mx-1 d-flex justify-center align-center">
              <v-btn-toggle dense>
                <v-btn
                  style="border-width: 1px !important;"
                  :disabled="!offersDelivery || checkBreakfastMode"
                  :color="isDelivery ? 'success' : ''"
                  @click="toggleService(true)"
                  :dark="isDelivery"
                  height="35"
                >
                  <v-badge
                    v-if="offersDelivery"
                    :value="!!!deliveryInfo"
                    color="error"
                    left
                  >
                    <template v-slot:badge>
                      <v-icon color="white" size="15"
                        >mdi-map-marker-question-outline
                      </v-icon>
                    </template>
                    <font-awesome-icon
                      :icon="['fal', 'biking-mountain']"
                      :style="{
                        color: isDelivery ? 'white' : 'black',
                        fontSize: '18px'
                      }"
                    ></font-awesome-icon>
                    <span :style="{ color: isDelivery ? 'white' : 'black' }">{{
                      $t("delivery.lang_delivery")
                    }}</span>
                  </v-badge>
                  <template v-else>
                    <font-awesome-icon
                      :icon="['fal', 'biking-mountain']"
                      :style="{ color: 'black', fontSize: '18px' }"
                    ></font-awesome-icon>
                    <span :style="{ color: 'black' }">{{
                      $t("delivery.lang_delivery")
                    }}</span>
                  </template>
                </v-btn>

                <v-btn
                  style="border-width: 1px !important;"
                  :disabled="!offersPickUp"
                  :color="!isDelivery ? 'success' : ''"
                  :dark="!isDelivery"
                  height="35"
                  @click="toggleService(false)"
                >
                  <font-awesome-icon
                    :icon="['fal', 'burger-soda']"
                    :style="{
                      color: !isDelivery ? 'white' : 'black',
                      fontSize: '18px'
                    }"
                  ></font-awesome-icon>
                  <span :style="{ color: !isDelivery ? 'white' : 'black' }">{{
                    $t("delivery.lang_pickup")
                  }}</span>
                </v-btn>
              </v-btn-toggle>
            </div>
            <h6 class="font-weight-bolder my-2">
              {{ $t("delivery.lang_yourChoice") }}
            </h6>
            <!--Start Shopping Cart Orders-->
            <div v-if="checkBreakfastMode" class="mx-1">
              <shopping-cart-orders-card
                v-for="day in days"
                :key="day"
                :day="day"
              />
            </div>
            <div v-else class="mx-1">
              <shopping-cart-orders-card />
            </div>
            <!--End Shopping Cart Orders-->

            <div class="px-6 mt-3 mx-auto text-center">
              <div class="d-flex" v-if="isDelivery">
                <span class="caption grey--text">{{
                  $t("delivery.lang_subtotal")
                }}</span>
                <v-spacer></v-spacer>
                <span class="caption grey--text">
                  {{ cartTotalSum | currency }}
                </span>
              </div>
              <div class="d-flex" v-if="isDelivery">
                <span class="caption grey--text">{{
                  $t("delivery.lang_deliveryCosts")
                }}</span>
                <v-spacer></v-spacer>
                <span class="caption grey--text" v-if="deliveryInfo">
                  {{
                    deliveryInfo.deliveryCosts
                      ? deliveryInfo.deliveryCosts
                      : "free" | currency
                  }}
                </span>
                <span v-else class="caption">
                  {{ $t("delivery.lang_pleaseSet") }}
                </span>
              </div>

              <div class="d-flex">
                <span class="caption font-weight-bold primary--text">
                  {{ $t("delivery.lang_total") }}
                </span>
                <v-spacer></v-spacer>
                <TotalWrapper :total="totalSumWithVoucher" class="caption font-weight-bold primary--text"/>
              </div>

              <p v-if="isDelivery" class="mt-4 grey--text">
                {{ $t("delivery.lang_minimumOrderValue") + " " }}
                {{
                  deliveryInfo ? deliveryInfo.minimumOrderValue : 0 | currency
                }}.
                {{ $t("delivery.lang_reachMinimumOrderValue") }}
              </p>
              <p v-else class="mt-4 grey--text">
                {{ $t("delivery.lang_pickupAddressIs") }}
                {{
                  shop &&
                    shop.street +
                      " " +
                      shop.streetNumber +
                      " " +
                      shop.zip +
                      " " +
                      shop.city
                }}
              </p>
            </div>

            <v-sheet height="200px" color="transparent" />
          </v-card-text>

          <!--Start dialog Basket button-->
          <v-card-actions>
            <v-btn
              v-if="canOrder"
              @click="redirectToCheckout()"
              width="100%"
              height="65px"
              color="accent"
              dark
              class="text-capitalize"
              style="border-top-right-radius: 60px; border-top-left-radius: 60px; position: fixed; bottom: 0%; right: 0%; left: 0%;"
            >
              <div class="d-flex justify-center">
                <h4 class="font-weight-regular white--text pt-1 pr-2">
                  {{ $t("delivery.lang_order") }}
                </h4>

                <v-icon size="26px" color="white" dark>
                  mdi-arrow-right
                </v-icon>
              </div>
            </v-btn>
          </v-card-actions>
          <!--End Dialog Basket button-->
        </v-card>
        <!--End Shopping Cart Content-->
      </v-dialog>
    </div>
    <!--End shopping cart-->
  </div>
</template>

<script>
/*import components*/
import ShoppingCartOrdersCard from "@/components/Card/ShoppingCartOrdersCard";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faBikingMountain,
  faBurgerSoda
} from "@fortawesome/pro-light-svg-icons";
/*import plugins*/
import { createNamespacedHelpers } from "vuex";
import { isBreakfast } from "../../helpers/breakfast";
import TotalWrapper from "@/components/Widget/TotalWrapper.vue";

library.add({
  faBurgerSoda,
  faBikingMountain
});

export default {
  name: "ShoppingCart",

  components: {
    ShoppingCartOrdersCard,
    "font-awesome-icon": FontAwesomeIcon,
    TotalWrapper
  },

  data() {
    return {
      addresses: []
    };
  },
  watch: {
    isDelivery(val) {
      if (this.isLoggedIn && this.isDelivery) {
        this.getUserAddress();
      }
    }
  },

  mounted() {
    if (this.isLoggedIn && this.isDelivery) {
      this.getUserAddress();
    }
  },
  methods: {
    ...createNamespacedHelpers("Config").mapMutations(["setDelivery"]),
    ...createNamespacedHelpers("Order").mapMutations(["setItemPrices"]),

    ...createNamespacedHelpers("Config").mapMutations(["setDeliveryInfo"]),
    //Redirect To Checkout Page
    //Redirect To Checkout Page
    redirectToCheckout() {
      if (!this.canOrder) {
        this.$root.$emit("toggleDeliveryAreaDialog", false);
        return;
      }

      if (this.isDelivery) {
        if (this.deliveryInfo) {
          if (
            this.cartTotalSum >= this.deliveryInfo.minimumOrderValue &&
            !this.isCartEmpty
          ) {
            this.$router.push("/checkout-order");
            this.$root.$emit("toggleDeliveryAreaDialog", false);
            return;
          } else {
            this.$swal({
              title: this.$t("generic.lang_Warning"),
              icon: "warning",
              text: this.$t("delivery.lang_minimumOrderValueNotReached")
            });
            this.$root.$emit("toggleDeliveryAreaDialog", false);
          }
        } else {
          this.$root.$emit("toggleDeliveryAreaDialog", true);
          return;
        }
        this.$root.$emit("toggleDeliveryAreaDialog", false);
      } else {
        this.$router.push("/checkout-order");
        this.$root.$emit("toggleDeliveryAreaDialog", false);
      }
    },
    toggleService(status) {
      if (this.$route.path !== "/") {
        if (!(!this.isCartEmpty && !this.maintenanceMode)) {
          this.$router.push("/");
        }

        if (this.isDelivery) {
          if (this.deliveryInfo) {
            if (
              !(
                this.cartTotalSum >= this.deliveryInfo.minimumOrderValue &&
                !this.isCartEmpty
              )
            ) {
              this.$router.push("/");
            }
          }
        }
      }

      if (status) {
        if (!this.offersDelivery) {
          this.$swal({
            title: this.$t("generic.lang_Warning"),
            icon: "warning",
            text: this.$t("delivery.lang_cantAcceptDelivery")
          });
        }
      } else {
        if (!this.offersPickUp) {
          this.$swal({
            title: this.$t("generic.lang_Warning"),
            icon: "warning",
            text: this.$t("delivery.lang_cantAcceptPickup")
          });
        }
      }

      if (this.isDelivery && status) {
        this.$root.$emit("toggleDeliveryAreaDialog", true);
      }

      this.setDelivery(status);
      this.setItemPrices(status);
    },

    getUserAddress() {
      this.addresses = [];
      this.axios
        .get("localbee/v2/account/address")
        .then(res => {
          if (res.status === 200) {
            let defaultAddress = res.data.data.find(
              address => address.default === 1
            );
            let area = null;
            if (defaultAddress && !this.deliveryInfo) {
              area = this.deliveryAreas.find(
                searchArea => searchArea.zip === defaultAddress.zip
              );
              if (area) this.setDeliveryInfo(area);
              else
                this.$swal({
                  title: this.$t("generic.lang_Warning"),
                  icon: "warning",
                  text: this.$t("delivery.lang_cantDeliverToZipCode")
                });
            }
          } else {
          }
        })
        .catch(err => {
          if (err.status === 500) {
            this.$swal({
              title: this.$t('delivery.lang_error'),
              icon: "error",
              text: this.$t('delivery.lang_somethingWentWrongPleaseTryAgainLater')
            });
          }
        })
        .finally(() => {});
    },

    setDeliverArea(deliveryArea) {
      this.setDeliveryInfo(deliveryArea);
    }
  },

  props: {
    dialog: Boolean
  },

  computed: {
    ...createNamespacedHelpers("Order").mapGetters([
      "cart",
      "isCartEmpty",
      "cartTotalSum",
      "totalSum",
            "cartTotalSumWithVoucher",
      "totalSumWithVoucher",
    ]),

    ...createNamespacedHelpers("Config").mapGetters([
      "maintenanceMode",
      "offersDelivery",
      "offersPickUp",
      "isDelivery",
      "shop",
      "deliveryInfo",
      "deliveryAreas",
      "enableOrderScheduling",
      "isStillOpen"
    ]),

    ...createNamespacedHelpers("auth").mapGetters(["isLoggedIn"]),

    checkBreakfastMode() {
      return isBreakfast();
    },
    days() {
      if (!this.isCartEmpty && this.checkBreakfastMode) {
        const days = [...this.cart].map(({ date }) => date);
        return days.filter((day, index) => days.indexOf(day) === index);
      }

      return [];
    },
    canOrder() {
      let can =
        !this.isCartEmpty &&
        !this.maintenanceMode &&
        (this.isStillOpen || this.enableOrderScheduling) &&
        this.$route.path === "/" &&
        (this.isDelivery ? this.offersDelivery : this.offersPickUp);
      return can;
    },

    /*get number of selected items*/
    NProductSelect() {
      return this.cart.length;
    },

    /*for:hossaine, we're using computed attr to oid mutating a 'dialog' prop directly*/
    visible: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
