import {i18n} from "../../main";

export default{

    beforeRouteEnter(from,to,next){
        next(vm=>{
            window.store.dispatch("Config/loadStoreInfo").then(() => {
                if(window.store.getters['Config/isStillOpen'] || window.store.getters['Config/enableOrderScheduling']){
                    if(window.store.getters['Config/isDelivery'] ){
                        if(window.store.getters['Config/deliveryInfo']){
                            if(window.store.getters['Order/cartTotalSum']<window.store.getters['Config/deliveryInfo'].minimumOrderValue){
                                vm.$router.replace({
                                    name: 'RestaurantMenu',
                                    query : {msg: i18n.t("delivery.lang_pleaseReachTheMinimumDeliveryOrderValue")}
                                })
                            }
                        }else{
                            vm.$router.replace({
                                name: 'RestaurantMenu',
                                query : {msg: i18n.t("delivery.lang_pleaseSetTheDeliveryArea")}
                            })
                        }
                    }

                    if(window.store.getters['Order/isCartEmpty']){
                        vm.$router.replace({
                            name: 'RestaurantMenu',
                            query : {msg: i18n.t("delivery.lang_pleaseAddItemsToYourCart")}
                        })
                    }
                }else {
                    vm.$router.replace({
                        name: 'RestaurantMenu',
                    })
                }
            });
        })
    }
}